<template>
	<div class="">
		<el-dialog  v-dialogDrag title="服务记录" :visible.sync="showStatusLog" width="40%" :show-close="true"
			@close="showStatusLog=false">
			<div class="overflow-hidden columnScorll" style="max-height: 500px;">
				<el-timeline :reverse="reverse">
					<el-timeline-item placement="top" v-for="(item, index) in logList" :key="index"
						:timestamp="item.operator">
						<div class="fontColor-grey">{{item.date}}</div>
						<div class="font16 fontColor-org " style="white-space: pre-wrap;">
							{{item.reasonRejection}}
						</div>
					</el-timeline-item>
				</el-timeline>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		statusLogList
	} from "@/api/common.js";
	export default {
		props: {
			id: { //案件id
				type: Number,
				default: 0
			},
			type: { //页面类型
				type: String,
				default: ""
			},
			//诉讼ID
			litigationId:{
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				reverse: false,
				logList: [],
				showStatusLog: false
			}
		},
		watch:{
			id:{
				handler(newVal,oldVal){
					if(newVal){
						this.getData()
					}
				},
				deep:true
			}
		},
		mounted() {
			console.log("--------",this.id,this.type,this.litigationId);
			this.getData();
		},
		methods: {
			getData() {
				statusLogList({
					currentId: this.id,
					type: this.type,
				}).then((res) => {
					this.logList = res.data;
					if(this.type == '02'){
						this.getLawyerLog()
					}
				})
			},
			getLawyerLog() {
				statusLogList({
					currentId: this.litigationId,
					type: 10
				}).then((res) => {
					this.logList = [...this.logList,...res.data];
				})
			}
		},
	}
</script>

<style scoped>
	::v-deep .el-dialog__header {
		display: block !important;
		padding:20px !important;
	}
</style>