import request from '@/utils/request'

// 获取体检次数--用户端
export function riskCheckupNum(data) {
	return request({
		url: '/case/statistic/riskCheckup/num',
		method: 'get',
		params: data
	})
}


// 获取律师咨询次数--用户端
export function legalAdviceNum(data) {
	return request({
		url: '/case/statistic/legalAdvice/num',
		method: 'get',
		params: data
	})
}


// 获取纠纷处理次数--用户端
export function clericalServiceNum(data) {
	return request({
		url: '/case/statistic/clericalService/num',
		method: 'get',
		params: data
	})
}


// 获取合同审查次数--用户端
export function contractReviewNum(data) {
	return request({
		url: '/case/statistic/contractReview/num',
		method: 'get',
		params: data
	})
}

// 首页获取合案件委托进度
export function getMyCaseList(data) {
	return request({
		url: '/case/nonCaseEntrustment/getMyCaseList',
		method: 'get',
		params: data
	})
}
// 意见反馈 title content
export function addfeedback(data) {
	return request({
		url: "/system/feedback",
		method: 'post',
		data: data
	})
}

/* 获取通知 */
export function noticeList(data) {
	return request({
		url: '/system/userNotice/myList',
		method: 'get',
		params: data
	})
}
/* 全部标记为已读 */
export function readAllNotice(data) {
	return request({
		url: '/system/userNotice/readAllNotice',
		method: 'put'
	})
}
/* 全部标记为已读 */
export function readNotice(data) {
	return request({
		url: '/system/userNotice',
		method: 'put',
		data
	})
}
/* 删除通知 */
export function removeNotice(data) {
	return request({
		url: '/system/userNotice/' + data,
		method: 'delete'
	})
}


/* 获取类型 */
export function feedbackType(data) {
	return request({
		url: '/system/dict/data/type/feedback_type',
		method: 'get',
		params: data
	})
}


/* 获取首页合同通知 */
export function getMylists(data) {
	return request({
		url: '/system/userNotice/getlistByUser',
		method: 'get',
		params: data
	})
}


// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
	const data = {
		oldPassword,
		newPassword
	}
	return request({
		url: '/system/user/profile/updatePwd',
		method: 'put',
		params: data
	})
}

/* 关于我们 0    1-联系我们 */
export function getInfo(data) {
	return request({
		url: '/system/aboutInfo/public/getInfo',
		method: 'get',
		params: data
	})
}

/* 常见问题*/
export function getHelpList(data) {
	return request({
		url: '/system/help/public/getHelpList',
		method: 'get',
		params: data
	})
}

/* 常见问题详情 */
export function getHelpInfo(id) {
	return request({
		url: '/system/help/public/getHelpInfo/' + id,
		method: 'get'
	})
}

/* 获取系统设置 */
export function configKey(key) {
	return request({
		url: '/system/config/configKey/' + key,
		method: 'get'
	})
}



/* 新增留言 */
export function leaveMessageAdd(data) {
	return request({
		url: '/portal/leaveMessageAdd',
		method: 'post',
		data: data
	})
}


/* 获取系统公告 */
export function getNotice(data) {
	return request({
		url: '/system/notice/list',
		method: 'get',
		params: data
	})
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

//获取参数
export function getConfigKey(key) {
	return request({
	  url: '/system/config/configKey/'+key,
	  method: 'get'
	})
}